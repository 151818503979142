import React, { useRef, useState } from "react";
import {
  TransformWrapper,
  TransformComponent
} from "react-zoom-pan-pinch";
import mapImg from "../images/map.jpeg";
import './Map.scss';



const Map = () => {
  const transformComponentRef = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [activeType, setActiveType] = useState(null);

  const zoomToId = (id) => {
    console.log("zoomToId", id);
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      setActiveMarker(id);
      zoomToElement(id);
    }
  };

  const Controls = ({ zoomIn, zoomOut, resetTransform }) => {

    const zoomInWithStep = () => {
      // Adjust the zoom factor to your desired smaller step
      const smallerStepZoomFactor = 0.2;
      zoomIn(smallerStepZoomFactor);
    };
  
    const zoomOutWithStep = () => {
      // Adjust the zoom factor to your desired smaller step
      const smallerStepZoomFactor = 0.2;
      zoomOut(smallerStepZoomFactor);
    };
  
    return(
      <>
        <button onClick={() => zoomInWithStep()}>Zoom in +</button>
        <button onClick={() => zoomOutWithStep()}>Zoom out -</button>
        <button onClick={() => {
          resetTransform()
          setActiveMarker(null)
          setActiveType(null)
        }}>Reset</button>
      </>
    )
  };

  return (
    <div className="map-container">
      <h1>Taste London Map</h1>
      <div className="map-wrap">
        <div className="map-map">
          <TransformWrapper
            initialScale={1}
            // initialPositionX={200}
            // initialPositionY={100}
            maxScale={3}
            limitToBounds={false}
            ref={transformComponentRef}
          >
            {(utils) => (
              <React.Fragment>
                <Controls {...utils} />
                <TransformComponent>
                  <div className="map-contents">
                    {/* <img src={mapImg} alt="test" id="imgExample" /> */}

                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 750">
                      <rect className="cls-1" width="650" height="750" fill="#f4214f" />
                      <path className="cls-2" d="M317,67,467,221l20,262,93,179L401,675l-171,7L94,583,69,437s123-21,125-21S317,67,317,67Z" fill="#429956" />
                      {(activeType === "ttd" || activeType === null) && (
                        <>
                          <circle className={`marker ${activeMarker === "ttd1" ? "active" : ""}`} cx="321" cy="103" r="9" fill="#f4f40b" id="ttd1" onClick={() => zoomToId("ttd1")} />
                          <circle className={`marker ${activeMarker === "ttd2" ? "active" : ""}`} cx="293" cy="266" r="9" fill="#f4f40b" id="ttd2" onClick={() => zoomToId("ttd2")}/>
                          <circle className={`marker ${activeMarker === "ttd3" ? "active" : ""}`} cx="148" cy="526" r="9" fill="#f4f40b" id="ttd3" onClick={() => zoomToId("ttd3")}/>
                          <circle className={`marker ${activeMarker === "ttd4" ? "active" : ""}`} cx="253" cy="414" r="9" fill="#f4f40b" id="ttd4" onClick={() => zoomToId("ttd4")}/>
                          <circle className={`marker ${activeMarker === "ttd5" ? "active" : ""}`} cx="280" cy="294" r="9" fill="#f4f40b" id="ttd5" onClick={() => zoomToId("ttd5")}/>
                        </>
                      )}
                      {(activeType === "r" || activeType === null) && (
                        <>
                          <circle className={`marker ${activeMarker === "r1" ? "active" : ""}`} cx="397" cy="473" r="9" fill="#4a0cf2" id="r1" onClick={() => zoomToId("r1")}/>
                          <circle className={`marker ${activeMarker === "r2" ? "active" : ""}`} cx="397" cy="503" r="9" fill="#4a0cf2" id="r2" onClick={() => zoomToId("r2")}/>
                          <circle className={`marker ${activeMarker === "r3" ? "active" : ""}`} cx="397" cy="533" r="9" fill="#4a0cf2" id="r3" onClick={() => zoomToId("r3")}/>
                          <circle className={`marker ${activeMarker === "r4" ? "active" : ""}`} cx="523" cy="646" r="9" fill="#4a0cf2" id="r4" onClick={() => zoomToId("r4")}/>
                          <circle className={`marker ${activeMarker === "r5" ? "active" : ""}`} cx="249" cy="664" r="9" fill="#4a0cf2" id="r5" onClick={() => zoomToId("r5")}/>
                        </>
                      )}
                    </svg>
                  </div>
                  
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
        <div className="map-menu">
          <button onClick={() => setActiveType("r")} className={`title-btn ${activeType === "r" ? "active" : ""}`}>
            <h2>Restaurants</h2>
          </button>
          <ul>
            <li>
              <button onClick={() => zoomToId("r1")}>Chai Wu - R1</button>
            </li>
            <li>
              <button onClick={() => zoomToId("r2")}>Hotbox - R2</button>
            </li>
            <li>
              <button onClick={() => zoomToId("r3")}>Daily Special 2 - R3</button>
            </li>
            <li>
              <button onClick={() => zoomToId("r4")}>Daily Special 2 - R3</button>
            </li>
            <li>
              <button onClick={() => zoomToId("r5")}>Daily Special 2 - R3</button>
            </li>
          </ul>
          <button onClick={() => setActiveType("ttd")} className={`title-btn ${activeType === "ttd" ? "active" : ""}`}>
            <h2>Things to do</h2>
          </button>
          <ul>
            <li>
              <button onClick={() => zoomToId("ttd1")}>Taste Sessions in partnership with The Royal Parks - TTD1</button>
            </li>
            <li>
              <button onClick={() => zoomToId("ttd2")}>Cook School with Lee Kum Kee - TTD2</button>
            </li>
            <li>
              <button onClick={() => zoomToId("ttd3")}>The Fire Pit with Coca-Cola Zero - TTD3</button>
            </li>
            <li>
              <button onClick={() => zoomToId("ttd4")}>BBQ Cook School - TTD4</button>
            </li>
            <li>
              <button onClick={() => zoomToId("ttd5")}>Villa Poretti - TTD5</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Map;